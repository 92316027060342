
::v-deep p {
    margin: 0;
}
.hotScripts-container {
    position: relative;
    overflow: hidden;
    .hotScripts-classify {
        background: #fff;
        border-radius: 8px;
        display: flex;
        flex-wrap: wrap;
        padding: 20px 20px 10px;
        .classify-left {
            line-height: 30px;
        }
        .classify-right {
            width: 1%;
            flex: 1;
            .c-item {
                cursor: pointer;
                border-radius: 4px;
                display: inline-block;
                line-height: 30px;
                padding: 0 10px;
                margin: 0 0 10px 10px;
                transition: all .3s;
                &.current {
                    color: #2338E6;
                    background: #BEC3F1;
                }
                &:hover {
                    color: #2338E6;
                }
            }
        }
    }
    .hotScripts-content {
        /*height: 1%;*/
        /*flex: 1;*/
        //margin-top: -20px;
        margin-right: -20px;
        display: flex;
        flex-wrap: wrap;
        /*min-height: calc(100vh - 212px);*/
        /*::v-deep .el-scrollbar__wrap {*/
        /*    overflow-x: hidden;*/
        /*    .el-scrollbar__view {*/
        /*        display: flex;*/
        /*        flex-wrap: wrap;*/
        /*    }*/
        /*}*/
        .h-item {
            width: calc(25% - 20px);
            margin: 20px 20px 0 0;
            border-radius: 8px;
            overflow: hidden;
            .h-item-cover {
                width: 100%;
                height: 0;
                padding-bottom: 57.397959%;
                position: relative;
                overflow: hidden;
                cursor: pointer;
                background: #fff;
                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .h-item-content {
                background: #fff;
                padding-bottom: 16px;
                .line {
                    padding: 16px 16px 0;
                    &.line-two {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                    .title {
                        color: #333;
                        width: 100%;
                        display: block;
                        cursor: pointer;
                        &:hover {
                            color: #1E33E3;
                        }
                    }
                    .icon-hot {
                        color: #FF0000;
                        margin-left: 10px;
                        font-size: 18px;
                        line-height: 1;
                    }
                    .avatar {
                        display: flex;
                        align-items: center;
                        .avatar-cover {
                            width: 24px;
                            height: 24px;
                            border-radius: 50%;
                            overflow: hidden;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            img {
                                max-width: 100%;
                                max-height: 100%;
                            }
                        }
                        .name {
                            margin-left: 10px;
                            font-size: 12px;
                        }
                    }
                    .btn {
                        .el-link {
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
    }
}
.el-link.blue {
    color: #1E33E3;
    &:hover {
        color: #3449fa;
    }
}
.no-data {
    width: 100%;
    margin-top: 100px;
    text-align: center;
}
